.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

th {
  position: sticky;
  top: 0;
}

.table-container {
  overflow: auto;
  /* max-height: 160px; */
}

.test{
    content: '\2713\0020';
    transition: all .2s;
    font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
    font-size: 1.5em;
    line-height: 1;
    margin-top: -23px;
    margin-left: -6px;
    text-align: center;
    border: 1px solid #1EB863;
    background-color: #1EB863;
    color: white;
    border-radius: 5px /*text-shadow:1px 1px #B0BED9,-1px -1px #B0BED9,1px -1px #B0BED9,-1px 1px #B0BED9; */;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #4A4D5C ;
  border-color: #4A4D5C ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #4A4D5C ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #4A4D5C ;
  border-color: #4A4D5C;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #4A4D5C
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

.btn.btn-outline-primary.mb-1{
padding:0.1rem .6rem
}
.sidebar-hover:hover {
  color: #4A4D5C;
}

.error-input , .error-input:focus{
  border-color: red !important;
}

.error-img-upload, .error-img-upload:focus{
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
}

.Select.is-open { position: relative; z-index: 1000; }

.cursor-img{
  cursor: pointer;
}
.rdl-control {
  height: 400px;
}

.small-buttom {
  padding: 0.1rem .6rem !important;
  border-radius: 5px;
  font-size: 0.875rem;
}

.global_filter{
border-color: hsl(0,0%,80%);
}


.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input ,.react-autosuggest__input:focus{
  width: 97%;
  height: 30px;
  padding: 18px 30px;
  font-weight: 300;
  font-size: 16px;
  /* border: 1px solid #aaa; */
  border: 1px solid #e3e7ed;
  border-radius: 25px;
  margin-right: auto;
  margin-bottom: auto;
  background-color: rgb(252, 252, 252);
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}


.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 38px;
  width: 97%;
  border-bottom: 1px solid #e3e7ed;
  border-right: 1px solid #e3e7ed;
  border-left: 1px solid #e3e7ed;
  background-color: rgb(252, 252, 252);
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 99;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 4px 8px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0C7EAF;
  color: #fff;
}

.react-autosuggest__suggestion:hover {
  background-color: #2eaee6;
  color: #fff;
}

.react-autosuggest__suggestion:hover .highlight  {
  color: #120000;

}


.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.name {
  margin-left: 68px;
  line-height: 45px;
}

.highlight {
  color: #ee0000;
  font-weight: bold;
}

.suggestionHighlighted {
  background-color: #0C7EAF;
  color: #fff;
}

.sugg {
  display: inline-block
}
.dropdown-menu{
  max-height: 600px;
  overflow-y: scroll;
}

.sticky-div {
  overflow: auto;
  width:100%;
  height: 100%;
}
.sticky-col {
  position:sticky;
  top:1;
  left:0;
  /* z-index:9; */
  background-color:white;
}

.sticky-table {
  table-layout: fixed;
  width:100%;
}

.sticky-head {
  position:sticky;
  left:0;
  top:1;
  z-index:9;
}

.one-sticky{
  position:sticky;
  left:0;
  z-index:99;
  background-color:#f1f3f6
}

.second-sticky{
  position:sticky;
  left:400;
  z-index:99;
  top: auto;
  background-color:#f1f3f6;
}

